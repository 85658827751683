import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "march-29-2019"
    }}>{`March 29, 2019`}</h2>
    <p>{`Carbon v10 is released. The Carbon Design System adopts the new IBM Design Language.`}</p>
    <ul>
      <li parentName="ul">{`There are extensive visual changes, and some functional changes, to all components.`}</li>
      <li parentName="ul">{`There should be feature parity with Carbon v9.`}</li>
      <li parentName="ul">{`For full information on component changes, please refer to the migration guides;
`}<a parentName="li" {...{
          "href": "https://github.com/IBM/carbon-components/blob/master/docs/migration/migrate-to-10.x.md"
        }}>{`Carbon Components Vanilla Javascript`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/IBM/carbon-components-react/blob/master/docs/migration/migrate-to-10.x.md"
        }}>{`Carbon Components React`}</a></li>
      <li parentName="ul">{`Introduction of production ready `}<a parentName="li" {...{
          "href": "https://github.com/IBM/carbon-elements"
        }}>{`Carbon Elements`}</a>{` package.`}</li>
      <li parentName="ul">{`The Carbon Design System website at `}<a parentName="li" {...{
          "href": "https://next.carbondesignsystem.com"
        }}>{`next.carbondesignsystem.com`}</a>{` will convert to the www site on 4/1/2019.`}</li>
    </ul>
    <h2 {...{
      "id": "archive"
    }}>{`Archive`}</h2>
    <p><em parentName="p">{`Note: As the Carbon design system is constantly being improved, we can only ensure the most recent changelog is accurate. Below is an archive of previous updates, however we cannot guarentee the accuracy of those changes reported.`}</em></p>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">February 2019</AnchorLink>
      <AnchorLink mdxType="AnchorLink">October 2018</AnchorLink>
      <AnchorLink mdxType="AnchorLink">June 4, 2018: V9 release</AnchorLink>
      <AnchorLink mdxType="AnchorLink">May 16, 2018</AnchorLink>
      <AnchorLink mdxType="AnchorLink">March 9, 2018</AnchorLink>
      <AnchorLink mdxType="AnchorLink">February 23, 2018</AnchorLink>
      <AnchorLink mdxType="AnchorLink">February 9, 2018</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "february-2019"
    }}>{`February 2019`}</h2>
    <p>{`The Carbon v10 launch is just around the corner, and we’ve officially entered Beta! That means the core team has been hard at work ensuring the new system is ready to ship. We have tons of improvements headed your way, including implementation of the new IBM Design Language and an all-new website.`}</p>
    <p>{`We expect v10 to launch mid-March, so keep your eyes peeled. If you’re interested in getting started before our public launch, we’re always looking for beta testers to help us squash bugs and improve the Carbon Design System.`}</p>
    <h2 {...{
      "id": "october-2018"
    }}>{`October 2018`}</h2>
    <h3 {...{
      "id": "experimental"
    }}>{`Experimental`}</h3>
    <p>{`A new `}<a parentName="p" {...{
        "href": "/experimental/about/overview"
      }}>{`Experimental`}</a>{` section has been added to the website. Experimental components, designs, patterns, and other resources are work that is still under development.`}</p>
    <p><strong parentName="p">{`Experimental components, designs, and other resources are presented for testing and feedback. They are not intended for production use.`}</strong></p>
    <h2 {...{
      "id": "june-4-2018-v9-release"
    }}>{`June 4, 2018: V9 Release`}</h2>
    <h3 {...{
      "id": "website"
    }}>{`Website`}</h3>
    <ul>
      <li parentName="ul">{`Add-ons section has been removed. There is an `}<a parentName="li" {...{
          "href": "contributing/add-ons"
        }}>{`Add-ons`}</a>{` page that now lives under the Getting Started section`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/getting-started/contributing"
        }}>{`Contributing`}</a>{` page has been moved from the Guidelines to the Getting Started section`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/data-visualization/overview/general"
        }}>{`Data Visualization`}</a>{` has moved from under the components section into its own section`}</li>
    </ul>
    <h3 {...{
      "id": "components"
    }}>{`Components`}</h3>
    <h4 {...{
      "id": "new"
    }}>{`New`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/code-snippet/code"
        }}>{`Inline Code Snippet`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/tooltip/code"
        }}>{`Icon Tooltip`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/tooltip/code"
        }}>{`Definition Tooltip`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/modal/code"
        }}>{`Danger Modal`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/button/code"
        }}>{`Danger Primary Button`}</a></li>
    </ul>
    <h4 {...{
      "id": "updates-visual--interaction"
    }}>{`Updates (Visual & Interaction)`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/code-snippet/code"
        }}>{`Code snippets`}</a>{` now have a new style and new interaction options. There is also a variation with line numbers available.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/tooltip/code"
        }}>{`Interactive Tooltip`}</a>{` (formerly Tooltip) can now contain interactive elements and opens on click`}</li>
      <li parentName="ul">{`Updated all inputs so they meet the new WCAG 3:1 color ratio guidelines for defined objects`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/date-picker/code"
        }}>{`Date & Time Picker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/dropdown/code"
        }}>{`Dropdown`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/number-input/code"
        }}>{`Number Input`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/search/code"
        }}>{`Search`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/select/code"
        }}>{`Select`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/slider/code"
        }}>{`Slider`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/text-input/code"
        }}>{`Text Input`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/text-input/code"
        }}>{`Text Area`}</a></li>
    </ul>
    <h3 {...{
      "id": "color"
    }}>{`Color`}</h3>
    <h4 {...{
      "id": "go-check-out-the-swatches-page-to-see-the-new-color-options-and-updates"
    }}>{`Go check out the `}<a parentName="h4" {...{
        "href": "/guidelines/color/swatches"
      }}>{`Swatches`}</a>{` page to see the new color options and updates.`}</h4>
    <ul>
      <li parentName="ul">{`Colors have been updated to make them more accessible— changing hex values and roles for some of the swatches`}</li>
      <li parentName="ul">{`The new, updated palette is available with Design Kit`}</li>
      <li parentName="ul">{`The changes include (bold indicates a new color token)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$ui-05 – #5A6872`}</inlineCode>{` (passing 4.5:1, high contrast border, secondary icons)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$ui-04 – #8897A2`}</inlineCode>{` (passing 3:1, now used for emphasis borders)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$ui-03 – #DFE3E6`}</inlineCode>{` (not passing 3:1, now used for subtle borders)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$support-01 – #E0182D`}</inlineCode>{` (passing 4.5:1)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$support-01 – #CDD1D4`}</inlineCode>{` (not passing 4,5:1, used only for placeholder text)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$field-01 – #F4F7FB`}</inlineCode>{` (input field background on `}<inlineCode parentName="li">{`$ui-01`}</inlineCode>{`)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$field-02 – #FFFFFF`}</inlineCode>{` (input field background on `}<inlineCode parentName="li">{`$ui-02`}</inlineCode>{`)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$inverse-02 – #272d33`}</inlineCode>{` (passing 4,5:1)`}</li>
    </ul>
    <p>{`There are four new hover tokens added to the Carbon palette. They can now be found on the `}<a parentName="p" {...{
        "href": "/guidelines/color/swatches"
      }}>{`Swatches`}</a>{` page and are available in the Design Kit and palette plugin`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`$hover-primary – #30588c`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`$hover-primary-text – #294c86`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`$hover-row – #5596e6`}</inlineCode>{` at 10% opacity`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$hover-secondary – #3d70b2`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`$hover-danger – #bd1427`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "icons"
    }}>{`Icons`}</h3>
    <ul>
      <li parentName="ul">{`Check out the `}<a parentName="li" {...{
          "href": "/guidelines/iconography/library"
        }}>{`Iconography`}</a>{` page to see the new icon style`}</li>
      <li parentName="ul">{`All Icons are now `}<inlineCode parentName="li">{`16x16px`}</inlineCode>{` (24x24px is no longer being supported).`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`--glyph`}</inlineCode>{` modifier has been deprecated. Most of the glyph variations were replaced with a `}<inlineCode parentName="li">{`--solid modifier`}</inlineCode>{`. Check out the `}<a parentName="li" {...{
          "href": "https://github.com/ibm/carbon-icons/blob/master/docs/migration-guides/migration-v7.md"
        }}>{`icon migration docs`}</a>{` to see the status of all the icons.`}</li>
    </ul>
    <h3 {...{
      "id": "design-kit"
    }}>{`Design Kit`}</h3>
    <ul>
      <li parentName="ul">{`Download the latest version of the `}<a parentName="li" {...{
          "href": "https://github.com/ibm/carbon-design-kit"
        }}>{`Carbon Design Kit & Library`}</a></li>
      <li parentName="ul">{`Components on the Component page are now split up into five different artboards: Controls, Content, Navigation, Inputs, and Data Tables`}</li>
      <li parentName="ul">{`Library has been made more flexible and customizable`}</li>
      <li parentName="ul">{`Kit and library have been updated with the new colors, components, and icon styles.`}</li>
    </ul>
    <h2 {...{
      "id": "may-16-2018"
    }}>{`May 16, 2018`}</h2>
    <h3 {...{
      "id": "utilities"
    }}>{`Utilities`}</h3>
    <p>{`There is a new section called Utilities which gives guidance on common pattern handlings.
Unlike the component section, these guidelines offer usage guidance with minimal code documentation. To read more about each of these utilities see the sections below:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "patterns/common-actions"
        }}>{`Common Actions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "patterns/disabled-states"
        }}>{`Disabled States`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "patterns/filtering"
        }}>{`Filtering`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "patterns/loading"
        }}>{`Loading`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "patterns/overflow-content"
        }}>{`Overflow Content`}</a></li>
    </ul>
    <h3 {...{
      "id": "themes"
    }}>{`Themes`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "guidelines/themes"
      }}>{`Themes page`}</a>{` has been moved to the Style section.`}</p>
    <h2 {...{
      "id": "march-9-2018"
    }}>{`March 9, 2018`}</h2>
    <h3 {...{
      "id": "style"
    }}>{`Style`}</h3>
    <h4 {...{
      "id": "systematic-spacing"
    }}>{`Systematic spacing`}</h4>
    <p>{`We have tokenized our spacing and now offer two systemized scales. The Spacing Scale defines space within a component while the Layout Scale is used to help position elements on a page. Check out the new `}<a parentName="p" {...{
        "href": "guidelines/spacing"
      }}>{`Spacing`}</a>{` section to learn more about these scales and read the rest of the spacing guidelines.`}</p>
    <h3 {...{
      "id": "design-kit-1"
    }}>{`Design Kit`}</h3>
    <h4 {...{
      "id": "sketch-libraries"
    }}>{`Sketch Libraries`}</h4>
    <p>{`We have recorded a `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=Tm-s0Hcbwck&list=PL4BR_VlGD31aVhe1ScKk9UOhbi8YOL0dF"
      }}>{`Carbon Design Kit with Sketch Libraries Tutorial`}</a>{` over on our YouTube channel. It goes over how to download the Carbon Sketch Kit, import a Sketch Library, as well as how to get and accept library updates/notifications. It also gives a brief overview of how the Library is organized and how to use the overrides panel and library symbols.`}</p>
    <h2 {...{
      "id": "february-23-2018"
    }}>{`February 23, 2018`}</h2>
    <h3 {...{
      "id": "design-kit-2"
    }}>{`Design Kit`}</h3>
    <h4 {...{
      "id": "sketch-libraries-1"
    }}>{`Sketch Libraries`}</h4>
    <p>{`The Carbon Design Kit now includes Sketch Libraries, a better way to share and version symbols across Sketch files. Check out our `}<a parentName="p" {...{
        "href": "https://github.com/ibm/carbon-design-kit/wiki/Sketch-Libraries-Overview"
      }}>{`Sketch Libraries wiki`}</a>{` to get a quick tutorial on libraries.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "30.629139072847682%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "sketch libraries in the carbon design kit",
        "title": "sketch libraries in the carbon design kit",
        "src": "/static/38f11618201e23b9c0a5c7f80fef6d35/fb070/sketch-libraries.png",
        "srcSet": ["/static/38f11618201e23b9c0a5c7f80fef6d35/d6747/sketch-libraries.png 288w", "/static/38f11618201e23b9c0a5c7f80fef6d35/09548/sketch-libraries.png 576w", "/static/38f11618201e23b9c0a5c7f80fef6d35/fb070/sketch-libraries.png 1152w", "/static/38f11618201e23b9c0a5c7f80fef6d35/48b52/sketch-libraries.png 1208w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "sketch-plugins"
    }}>{`Sketch Plugins`}</h4>
    <p>{`With the latest release of the Design Kit, we’ve included the `}<a parentName="p" {...{
        "href": "https://github.com/andrewfiorillo/sketch-palettes"
      }}>{`Sketch Palettes`}</a>{` plugin, which allows you to load in pre-saved palettes for Sketch. You can import Carbon’s four color palettes (Carbon default theme, Data Vis primary, Data Vis secondary, and Data Vis tertiary) into your working Sketch document. There’s also a variety of other `}<a parentName="p" {...{
        "href": "https://github.com/ibm/carbon-design-kit/wiki/Suggested-Sketch-Plugins"
      }}>{`plugins`}</a>{` we recommend to help improve your workflow using Sketch.`}</p>
    <p>{`Download the latest kit today! `}<a parentName="p" {...{
        "href": "https://github.com/ibm/carbon-design-kit"
      }}>{`Carbon design kit`}</a>{`.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "26.490066225165563%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Sketch Palettes plugin",
        "title": "Sketch Palettes plugin",
        "src": "/static/e3d8c4d6ae7cc935a90f9fe7d78e3461/fb070/sketch-palettes.png",
        "srcSet": ["/static/e3d8c4d6ae7cc935a90f9fe7d78e3461/d6747/sketch-palettes.png 288w", "/static/e3d8c4d6ae7cc935a90f9fe7d78e3461/09548/sketch-palettes.png 576w", "/static/e3d8c4d6ae7cc935a90f9fe7d78e3461/fb070/sketch-palettes.png 1152w", "/static/e3d8c4d6ae7cc935a90f9fe7d78e3461/48b52/sketch-palettes.png 1208w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "carbon-sessions"
    }}>{`Carbon Sessions`}</h3>
    <p>{`Interested in getting some 1:1 time with the Carbon team? Carbon Sessions is a chance for anyone using the Carbon Design System to ask questions and get feedback. Sessions happen very Wednesday morning (Central Standard Time). Time slots are around 10 minutes, but feel free to book more than one if you need additional time.`}</p>
    <p>{`Sign up for a `}<a parentName="p" {...{
        "href": "https://github.com/ibm/design-system-website/wiki/Carbon-Sessions"
      }}>{`Carbon Sessions time slot`}</a>{` if you’re interested in chatting further!`}</p>
    <h2 {...{
      "id": "february-9-2018"
    }}>{`February 9, 2018`}</h2>
    <h3 {...{
      "id": "components-1"
    }}>{`Components`}</h3>
    <h4 {...{
      "id": "dropdown"
    }}>{`Dropdown`}</h4>
    <p>{`We’re excited to bring some added functionality that enhances our `}<a parentName="p" {...{
        "href": "/components/dropdown"
      }}>{`Dropdown`}</a>{` component. Filtering, Multi-select, and Inline Dropdown are new additions to our basic Dropdown component.`}</p>
    <p><em parentName="p">{`Note: This component is currently only available in `}<a parentName="em" {...{
          "href": "http://react.carbondesignsystem.com/?selectedKind=ComboBox&selectedStory=default&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel"
        }}>{`React`}</a>{`.`}</em></p>
    <img {...{
      "src": "/dropdown-new-5e78a395842be42c8f6963c5978617bc.gif",
      "alt": "example of Dropdown with Multi-Select"
    }}></img>
    <h4 {...{
      "id": "small-toggle"
    }}>{`Small Toggle`}</h4>
    <p>{`Small Toggle is a style variation of our traditional `}<a parentName="p" {...{
        "href": "/components/toggle"
      }}>{`Toggle`}</a>{` component. It’s more compact in size, which allows them to be used in Data Tables or inline with Form elements.`}</p>
    <img {...{
      "src": "/small-toggle-2f59fea8bae3849633f12a86c6854787.gif",
      "alt": "example of Small toggle"
    }}></img>
    <h4 {...{
      "id": "data-vis-bar-graph"
    }}>{`Data Vis: Bar Graph`}</h4>
    <p>{`Bar Graph is the newest addition to our Data Visualization collection. It comes in two variations: Bar Graph and Grouped Bar Graph. You can use this component to represent quantitative information, such as comparing the taco sales between Austin taco shops in the month of June.`}</p>
    <p>{`Read more about the usage of `}<a parentName="p" {...{
        "href": "/data-visualization/bar-graph/usage"
      }}>{`Bar Graph`}</a>{`, or check out our `}<a parentName="p" {...{
        "href": "https://codepen.io/team/carbon/pen/OzBKKN"
      }}>{`CodePen`}</a>{` to view the live demo.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "51.67785234899329%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Bar Graph example",
        "title": "Bar Graph example",
        "src": "/static/c742d731139ebdb09c90399da95d7c52/fb070/usage-bar-graph.png",
        "srcSet": ["/static/c742d731139ebdb09c90399da95d7c52/d6747/usage-bar-graph.png 288w", "/static/c742d731139ebdb09c90399da95d7c52/09548/usage-bar-graph.png 576w", "/static/c742d731139ebdb09c90399da95d7c52/fb070/usage-bar-graph.png 1152w", "/static/c742d731139ebdb09c90399da95d7c52/fb104/usage-bar-graph.png 1728w", "/static/c742d731139ebdb09c90399da95d7c52/53680/usage-bar-graph.png 1788w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "data-vis-tooltip"
    }}>{`Data Vis: Tooltip`}</h4>
    <p>{`We have created several Data Visualization Tooltip variations to show the value for a specific data point or increment range. Tooltips appear on hover above or below a data representation, depending on the use case.`}</p>
    <p>{`Read more about the usage of `}<a parentName="p" {...{
        "href": "/data-visualization/tooltip/usage"
      }}>{`Data Vis Tooltips`}</a>{`, or check out our `}<a parentName="p" {...{
        "href": "https://codepen.io/team/carbon/pen/rJWymp"
      }}>{`CodePen`}</a>{` to view the live demo.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "42.581300813008134%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "small tooltip in a bar graph",
        "title": "small tooltip in a bar graph",
        "src": "/static/a9b828ffe4aaa7cc7d1e558737a647e0/fb070/usage-small-tooltip.png",
        "srcSet": ["/static/a9b828ffe4aaa7cc7d1e558737a647e0/d6747/usage-small-tooltip.png 288w", "/static/a9b828ffe4aaa7cc7d1e558737a647e0/09548/usage-small-tooltip.png 576w", "/static/a9b828ffe4aaa7cc7d1e558737a647e0/fb070/usage-small-tooltip.png 1152w", "/static/a9b828ffe4aaa7cc7d1e558737a647e0/fb104/usage-small-tooltip.png 1728w", "/static/a9b828ffe4aaa7cc7d1e558737a647e0/2233b/usage-small-tooltip.png 1968w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      